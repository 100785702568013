<template>
  <v-data-table
    :headers="headers"
    :items="notes"
    :sort-desc="sortDesc"
    class="elevation-1"
  >
    <template #item.report_include="{ item }">
      <v-checkbox
        v-model="item.report_flag"
        @change="(e) => reportInclude(e, item)"
      ></v-checkbox>
    </template>
    <template #item.case_attachments="{ item }">
      {{ item.case_attachments ? item.case_attachments.length : 0 }}
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Notes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="mx-2" icon color="green" @click="refreshNotes">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <div
          id="inverter_btn"
          @click="openWindow(`/sites/` + site_id + `/inverters_list`)"
        >
          <img
            :src="require('@/assets/img/inverters-link.svg')"
            width="24"
            height="24"
            class="mt-1"
          />
          <!-- <v-icon size="28">mdi-car-battery</v-icon> -->
        </div>
        <v-btn
          elevation="0"
          color="transparent"
          size="28"
          id="native_link_btn"
          v-on:click.stop.prevent="openWindow(nativeLink)"
        >
          <img :src="require('@/assets/img/' + system + '.png')" />
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              id="floor_plan_btn"
              size="28"
              v-if="site_plans"
              v-on="on"
              v-on:click.stop.prevent="openWindow(site_plans)"
              >mdi-floor-plan</v-icon
            >
          </template>
          <span>View Site Plans</span>
        </v-tooltip>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-dialog v-model="showUploadModal" width="500">
          <v-card>
            <v-spacer>
              <v-alert
                type="success"
                dismissible
                v-model="showUploadSuccessAlert"
              >
                Attachment was successfully uploaded.
              </v-alert>
            </v-spacer>
            <v-card-title>Upload Attachment</v-card-title>
            <v-card-text>
              <Dropzone
                ref="uploadDropzone"
                :options="dropzoneOptions"
                @change="handleDropzoneChange"
                @error="handleDropzoneError"
              ></Dropzone>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-danger
                  shadow-danger
                "
                outlined
                @click="closeUpload"
              >
                Cancel
              </v-btn>
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-success
                  bg-gradient-success
                  shadow-default
                "
                @click="submitUpload"
                :disabled="!uploadFiles.length"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAttachment" max-width="640px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Attachments</span>
            </v-card-title>
            <v-card-title>
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-success
                  shadow-success
                "
                @click="showDialogAttachment"
                >New</v-btn
              >
            </v-card-title>
            <v-data-table
              :headers="attachment_headers"
              :items="attachments"
              sort-by="file"
              class="elevation-1"
            >
              <!-- <template #item.name="{ value }">
                  {{ value }}
              </template> -->
              <template #item.name="{ item }">
                <a
                  :href="item.file"
                  v-on:click.stop.prevent="openWindow(item.file)"
                >
                  {{ item.name }}
                </a>
              </template>
              <template v-slot:item.attachment_actions="{ item }">
                <v-icon small @click="deleteAttachment(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.note"
                      label="Note"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="user.claims.user_role == 'customer_view'"
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              New Note
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <Mentionable
                    :keys="['@']"
                    :items="items"
                    offset="6"
                    insert-space
                    @open="onOpen"
                    @apply="onApply"
                    >
                      <v-textarea
                        type="text"
                        class="form-control"
                        rows="1"
                        outlined
                        auto-grow
                        v-model="editedItem.note"
                        placeholder="Note"
                        @input="handleInput"
                      ></v-textarea>
                      <template #no-result>
                        <div class="dim">
                          No result
                        </div>
                      </template>
                      <template #item-@="{ item }">
                        <div class="user">
                          {{ item.value }}
                          <!-- <span class="dim">
                            ({{ item.customer.name }})
                          </span> -->
                        </div>
                      </template>
                      <!-- <template #item-#="{ item }">
                        <div class="issue">
                            <span class="number"> #{{ item.value }} </span>
                            <span class="dim">
                            {{ item.label }}
                            </span>
                        </div>
                      </template> -->
                    </Mentionable>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <Dropzone
                      ref="dropzone"
                      :options="dropzoneOptions"
                      @change="handleDropzoneChange"
                      @error="handleDropzoneError"
                    ></Dropzone>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-danger
                  shadow-danger
                "
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-success
                  bg-gradient-success
                  shadow-default
                "
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-danger
                  shadow-danger
                "
                @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-default
                  shadow-default
                "
                text
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small class="mr-2" @click="showAttachments(item)">
        mdi-paperclip
      </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      There is no data available
      <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import { Mentionable } from 'vue-mention';
import 'floating-vue/dist/style.css';
import Service from "@/services/Service.js";
Vue.use(VueToast);

import Dropzone from "@/components/Dropzone.vue";

export default {
  components: {
    Mentionable,
    Dropzone,
  },
  props: ["vendor_num", "subject", "case_id", "site_plans", "system", "nativeLink", "site_id", "site_name", "site_contacts"],

  // {
  //   text: "ID",
  //   value: "id",
  // },
  // {
  //         text: "Download Link",
  //         value: "file",
  //       },
  
  data: function () {
    return {
      dialog: false,
      sortDesc: true,
      showUploadModal: false,
      uploadFiles: [],
      unorganizedUsers: null,
      uploadError: "",
      activeNoteId: null,
      showUploadErrorAlert: false,
      showUploadSuccessAlert: false,
      dialogDelete: false,
      dialogAttachment: false,
      users: null,
      items: [],
      headers: [
        {
          text: "Note",
          value: "note",
        },            
        {
          text: "Include",
          value: "report_include"
        },
        {
          text: "Attachments",
          value: "case_attachments",
        },
        {
          text: "Updated At",
          value: "updated_at",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      attachment_headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "File Name",
          value: "name",
        },
        { text: "Actions", value: "attachment_actions", sortable: false },
      ],
      notes: [],
      attachments: [],
      // issues: [],
      text: "",
      emailTo: [],
      editedIndex: -1,
      editedAttachmentIndex: -1,
      editedAttachmentItem: {
        id: 0,
        file: "",
      },
      editedItem: {
        id: 0,
        note: "",
      },
      defaultItem: {
        id: 0,
        note: "",
      },
      dropzoneOptions: {
        maxFilesize: 50, // 50 MB
        acceptedFiles: 'image/*,application/pdf,.xlsx,.xls,.doc,.docx,.zip,.heic,.webp',
        maxFiles: 15,
      },
    };
  },

  computed: {
    user: function () {
      return this.$store.getters.currentUser;
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Note" : "Edit Note";
    },
  },

  watch: {
    case_id: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      console.log('reset values...');
      // reset to all sites on tab change
      this.initialize();
    },

    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  filters: {
    // capitalize: function (value) {
    //   if (!value) return "";
    //   value = value.toString();
    //   return value.charAt(0).toUpperCase() + value.slice(1);
    // },

    fileName: function (url) {
      if (url) {
        var filename = url.split("/").pop();
        return filename;
      } else {
        return "";
      }
    },

    count: function (arr) {
      if (arr) {
        return arr.length;
      } else {
        return 0;
      }
    },
  },
  methods: {
    onOpen (key) {
      this.items = key === '@' ? this.users : this.issues
    },

    onApply (item, key, replacedWith) {
      this.emailTo.push(item);
      console.log('saving email info', this.emailTo)
    },

    handleInput() {
      // Iterate through the mentions
      for (let i = 0; i < this.emailTo.length; i++) {
        const mention = this.emailTo[i];
        // Check if the mention is not present in the textarea content
        if (!this.editedItem.note.includes(mention.value)) {
          // Remove the mention from the list
          this.emailTo.splice(i, 1);
          i--;  // Adjust the index since we removed an item
        }
      }
    },

    reportInclude(e, item) {
      let params = {
        report_flag: e
      }
      let noteId = item.id
      Service.updateCaseNote(noteId, params)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          this.handleError(error, 'Error including note');
        });
    },

    initialize() {
      this.getData(this.case_id);
    },

    openWindow: function (link) {
      window.open(link, "_blank");
    },

    getData(case_id) {
      NProgress.start()
      Service.getCaseNotesByCase(case_id)
        .then((response) => {
          this.notes = response.data.map(note => ({
            ...note,
            case_attachments: Array.isArray(note.case_attachments) ? note.case_attachments : []
          }));
          // load users
          return Service.getUsers();
        })
        .then((response) => {
          this.users = response.data.map(u => {
            return {...u, value: u.name};
          });
          // load contacts
          return Service.getContacts();
        })
        .then((response) => {
          const additionalUsers = response.data.map(c => {
              return { ...c, value: `${c.name} ${c.last_name}` };
          });
          // Appending the additional users to the existing this.users array
          this.users = this.users.concat(additionalUsers);
          // rearrange the contacts to have siteContacts at the front
          const matchingContacts = [];
          const remainingContacts = [];
          for (const user of this.users) {
            let found = false;
            for (const contact of this.site_contacts) {
              if (user.id === contact.id) {
                found = true;
                break;
              }
            }
            if (!found) {
              remainingContacts.push(user);
            } else {
              matchingContacts.push(user);
            }
          }
          
          this.users = matchingContacts.concat(remainingContacts);
          
          NProgress.done();
        })
        .catch((error) => {
          this.handleError(error, 'Error loading data');
          NProgress.done();
        });
    },

    showDialogAttachment() {
      this.showUploadModal = true;
    },

    editItem(item) {
      this.editedIndex = this.notes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    refreshNotes() {
      this.getData(this.case_id);
      Vue.$toast.success(`Notes refreshed`, {
        position: "top-right",
      });
    },
    showAttachments(item) {
      Service.getNoteAttachments(item.id)
        .then((response) => {
          this.attachments = response.data;
          this.activeNoteId = item.id;
          this.dialogAttachment = true;
        })
        .catch((error) => {
          this.handleError(error, 'Error loading attachments');
        });
    },

    deleteItem(item) {
      this.editedIndex = this.notes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteAttachment(item) {
      this.editedAttachmentIndex = this.attachments.indexOf(item);
      this.editedAttachmentItem = Object.assign({}, item);
      const case_attachment_id = this.attachments[this.editedAttachmentIndex].id;
      Service.deleteCaseAttachement(case_attachment_id)
        .then((response) => {
          this.attachments.splice(this.editedAttachmentIndex, 1);
          // Update the case_attachments for the current note
          const noteIndex = this.notes.findIndex(note => note.id === this.activeNoteId);
          if (noteIndex !== -1) {
            this.notes[noteIndex].case_attachments = this.attachments;
          }
        })
        .catch((error) => {
          this.handleError(error, 'Error deleting attachment');
        });
    },

    deleteItemConfirm() {
      const note_id = this.notes[this.editedIndex].id;
      Service.deleteCaseNote(note_id)
        .then((response) => {
          this.notes.splice(this.editedIndex, 1);
          this.closeDelete();
        })
        .catch((error) => {
          this.handleError(error, 'Error deleting note');
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.uploadFiles = [];
        if (this.$refs.dropzone) {
          this.$refs.dropzone.removeAllFiles();
        }
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeUpload() {
      this.showUploadModal = false;
      this.uploadFiles = [];
      if (this.$refs.uploadDropzone) {
        this.$refs.uploadDropzone.removeAllFiles();
      }
    },

    submitUpload() {
      if (this.uploadFiles.length) {
        const uploadPromises = this.uploadFiles.map(file => 
          Service.uploadAttachment(file, this.activeNoteId)
        );

        Promise.all(uploadPromises)
          .then(responses => {
            responses.forEach(response => {
              const case_attachment = response.data;
              this.attachments.push(case_attachment);
            });
            this.showUploadModal = false;
            // Update the case_attachments for the current note
            const noteIndex = this.notes.findIndex(note => note.id === this.activeNoteId);
            if (noteIndex !== -1) {
              this.notes[noteIndex].case_attachments = this.attachments;
            }
            Vue.$toast.success(`Attachments uploaded successfully`, { position: "top-right" });
          })
          .catch(error => {
            this.handleError(error, 'Error uploading attachments');
          })
          .finally(() => {
            this.closeUpload();
          });
      } else {
        console.log("No files to upload");
        Vue.$toast.warning(`No files selected for upload`, { position: "top-right" });
      }
    },

    sendEmail(contact) {
        var email = contact.email;
        var subject = `Case # ${this.case_id} updated for site: ${this.site_name}`;
        var emailBody = 
          "Case Updated for Site: " + 
          this.site_name + 
          "/n Note:" + 
          this.editedItem.note + 
          "/n Click here to reply and see the full history: https://app.sunovision.com/cases/open-cases";
        document.location = 
          "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
    },

    save() {
      if (this.editedIndex > -1) {
        // update
        const id = this.notes[this.editedIndex].id;
        Service.updateCaseNote(id, { note: this.editedItem.note, report_flag: this.editedItem.report_flag })
          .then((response) => {
            Object.assign(this.notes[this.editedIndex], this.editedItem);
            return this.uploadAttachments(id);
          })
          .then(() => {
            // Update case_attachments after uploading
            return Service.getNoteAttachments(id);
          })
          .then((response) => {
            this.notes[this.editedIndex].case_attachments = response.data;
            Vue.$toast.success(`Note updated`, {
              position: "top-right",
            });
            this.close();
          })
          .catch((error) => {
            this.handleError(error, 'Error updating note');
          });
        //email user who was @ed
        Service.sendEmail(
          this.emailTo, 
          this.site_name, 
          this.editedItem.note, 
          this.subject, 
          this.vendor_num,
          this.case_id
        )
          .then((response) => {
            console.log(response);
            this.emailTo = [];
          })
          .catch((error) => {
            this.handleError(error, 'Error sending email');
            this.emailTo = [];
          });

        
      } else {
          Service.createCaseNote({
            case_id: this.case_id,
            note: this.editedItem.note,
            report_flag: true,
          })
            .then((response) => {
              const newNote = response.data;
              return this.uploadAttachments(newNote.id).then(() => newNote);
            })
            .then((newNote) => {
              // Get updated attachments after upload
              return Service.getNoteAttachments(newNote.id).then((response) => {
                newNote.case_attachments = response.data;
                return newNote;
              });
            })
            .then((newNote) => {
              this.notes.unshift(newNote);
              this.close();
              Vue.$toast.success(`Note created`, {
                position: "top-right",
              });
            })
            .catch((error) => {
              this.handleError(error, 'Error creating note');
            });
        Service.sendEmail(
          this.emailTo,
          this.site_name,
          this.editedItem.note,
          this.subject,
          this.vendor_num,
          this.case_id)
            .then((response) => {
              console.log(response);
              this.emailTo = [];
          })
            .catch((error) => {
              this.handleError(error, 'Error sending email');
              this.emailTo = [];
            });
      }
    },

    handleDropzoneChange(files) {
      this.uploadFiles = files;
      console.log('Files changed:', files); // Remove this line before commit
    },

    handleDropzoneError({ file, errorMessage }) {
      this.handleError(errorMessage, `Upload error: ${errorMessage}`);
    },

    uploadAttachments(noteId) {
      console.log('uploading attachments', this.uploadFiles)

      const uploadPromises = this.uploadFiles.map(file =>
        Service.uploadAttachment(file, noteId)
          .catch(error => {
            console.error('Upload error:', error);
            Vue.$toast.error(`Upload error: ${error.message}`, { position: "top-right" });
            throw error; // rethrow to be caught by Promise.all
          })
      );

      return Promise.all(uploadPromises)
        .catch(error => {
          this.handleError(error, 'One or more uploads failed');
        });
    },

    handleError(error, message = 'An error occurred') {
      console.error(error);
      Vue.$toast.error(message, { position: "top-right" });
    }
  },
};
</script>

<style>
#native_link_btn {
  margin-right: 1%;
  height: 32px;
  width: 36px;
  min-width: 32px;
  border: thin solid #ffffff;
}
#inverter_btn {
  margin-right: 1%;
}
#popper_na11muup_t6bfd7 {
  position: absolute;
  z-index: 1000;
}
.n-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: rgb(143, 171, 244);
}
.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}
</style>


